button.printButton {
    margin-left: 10px;
  }
  
  .iframe {
    position: absolute;
    width: 0;
    height:0;
    border:0;
  }
  
.info {
  color: green;
}

.warning {
  color: #ffa700;
}

.danger {
  color: red;
}
