.dashboard {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}

.clock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.actions {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 240px;
}

.actions > button {
  margin-bottom: 8px;
}

.tgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.tgColumn {
  max-width: 330px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.tgColumnGroup {
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.tgLinkContent {
  height: 150px;
  width: 150px;
}

.tgIcon {
  width: 50px;
  height: 50px;
}

.tgRootContainer {
  gap: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
